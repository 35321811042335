import React from 'react';

import { ImageTextSection, ImageTextSectionProps } from './ImageTextSection';
import { Body, ImageTextSectionsWrapper } from './styled';

export interface ImageTextSectionsProps {
  imageTextSections: ImageTextSectionProps[];
  reverse?: number;
}

export const ImageTextSections: React.FC<ImageTextSectionsProps> = ({
  imageTextSections,
  reverse = true,
}) => {
  return (
    <ImageTextSectionsWrapper>
      {imageTextSections.map((imageTextSection, index) => (
        <Body reverse={reverse}>
          <ImageTextSection
            image={imageTextSection.image}
            title={imageTextSection.title}
            subtitle={imageTextSection.subtitle}
            key={imageTextSection.image}
            reverse={index % 2}
          />
        </Body>
      ))}
    </ImageTextSectionsWrapper>
  );
};
