import React from 'react';

import { Title } from '../../atoms/Title/Title';

import { Pricing, PricingProps } from './Pricing';
import { PricingsWrapper } from './styled';

export interface PricingsProps {
  pricings: PricingProps[];
}

export const Pricings: React.FC<PricingsProps> = ({ pricings }) => {
  return (
    <PricingsWrapper>
      {pricings.map((pricing) => (
        <Pricing
          title={pricing.title}
          subtitle={pricing.subtitle}
          unite={pricing.unite}
          price={pricing.price}
          element={pricing.element}
          key={pricing.title}
        />
      ))}
    </PricingsWrapper>
  );
};
