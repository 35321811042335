import React from 'react';
import { PersonOutline } from 'react-ionicons';

import { Button } from '../../atoms/Buttons';
import { Title } from '../../atoms/Title/Title';

import {
  Body,
  Cellule,
  Footer,
  Heading,
  Icon,
  Price,
  Prix,
  SubTite,
  Tabbody,
  Tableau,
  Tabligne,
  TItle,
  Unite,
  Wrapper,
} from './styled';

export interface PricingProps {
  title: string;
  unite: string;
  price: string;
  subtitle: string;
  element: string;
}

export const Pricing: React.FC<PricingProps> = ({ element, title, subtitle, unite, price }) => (
  <Wrapper>
    <Heading>
      <Icon>
        <PersonOutline />
      </Icon>
      <TItle>{title}</TItle>
      <SubTite>{subtitle}</SubTite>
      <Prix>
        <Unite>{unite}</Unite>
        <Price>{price}</Price>
      </Prix>
    </Heading>
    <Body>
      <Tableau>
        <Tabbody>
          <Tabligne>
            <Cellule>{element}</Cellule>
          </Tabligne>
        </Tabbody>
      </Tableau>
    </Body>
    <Footer>
      <Button href="portfoliobaby" children="Choisir forfait" key={Button.name} />
    </Footer>
  </Wrapper>
);
