import styled from 'styled-components';

import { getMargin } from '../../../styles/helper/stylesHelper';
import { Device, Responsive } from '../../../styles/theme/responsive';

// Pricings styled

export const PricingsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > *:not(:last-child) {
    margin-right: ${getMargin('base')};
  }

  @media ${Responsive.lessThan(Device.mobile)} {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
      margin-bottom: ${getMargin('baseMobile')};
      margin-right: 0;
    }
  }
`;

// Pricing styled

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  background-color: white;
  border: 1px solid #00000033;

  :hover {
    border: 1px solid grey;
  }
`;

// Pricing Head
export const Heading = styled.div`
  width: 100%;
  text-align: center;
  padding: 50px 0 50px 0;
  justify-content: center;
`;

export const Icon = styled.div`
  margin-bottom: ${getMargin('base')};
  align-items: center;
`;

export const SubTite = styled.span`
  text-transform: uppercase;
`;

export const Prix = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
`;

export const Unite = styled.span``;

export const Price = styled.div`
  font-size: 50px;
`;

export const TItle = styled.h3`
  text-transform: none;
`;

// Pricing Body
export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Tableau = styled.table`
  padding: 0 50px 40px 50px;
`;

export const Tabbody = styled.tbody``;

export const Tabligne = styled.tr``;

export const Cellule = styled.td`
  line-height: ${getMargin('base')};
  white-space: pre-line;
  text-align: center;
`;

// Pricing footer

export const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 70px;
`;
