import { graphql } from 'gatsby';
import { getSrc, ImageDataLike } from 'gatsby-plugin-image';
import * as React from 'react';

import Layout from '../../components/Layout';
import { ImageTextSections } from '../../components/molecules/ImageTextSection';
import { PageSection } from '../../components/molecules/PageSection';
import { Pricings } from '../../components/molecules/Pricing';

interface PortfolioBabyQuery {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        description: string;
        presentation: {
          title: string;
          subtitle: string;
        };
        portfolios: {
          title: string;
          items: Array<{
            image: ImageDataLike;
            title: string;
            subtitle: string;
          }>;
        };
        pricings: {
          title: string;
          items: Array<{
            title: string;
            subtitle: string;
            price: string;
            unit: string;
            element: string;
          }>;
        };
      };
    };
    allMarkdownRemark: {
      totalCount: number;
      edges: Array<{
        node: {
          fields: {
            slug: string;
          };
          frontmatter: {
            title: string;
            featuredimage: ImageDataLike;
            description: string;
            date: string;
            tags: string[];
          };
        };
      }>;
    };
  };
}

const PortfolioBabyPage = ({ data }: PortfolioBabyQuery) => {
  const { portfolios, presentation, pricings } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <main>
        {presentation && (
          <PageSection
            title={presentation.title}
            subTitle={presentation.subtitle}
            withSection
            children={undefined}
          />
        )}

        {portfolios && (
          <PageSection title={portfolios.title} withSection backgroundColor="white">
            <ImageTextSections
              imageTextSections={portfolios.items.map((item) => ({
                image: getSrc(item.image) ?? '',
                title: item.title,
                subtitle: item.subtitle,
              }))}
            />
          </PageSection>
        )}

        {pricings && (
          <PageSection title={pricings.title} withSection>
            <Pricings
              pricings={pricings.items.map((item) => ({
                title: item.title,
                subtitle: item.subtitle,
                price: item.price,
                unite: item.unit,
                element: item.element,
              }))}
            />
          </PageSection>
        )}
      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  query PortfolioBabyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "portfolio-baby" } }) {
      frontmatter {
        title
        description
        presentation {
          title
          subtitle
        }
        portfolios {
          title
          subtitle
          items {
            title
            subtitle
            image {
              childImageSharp {
                gatsbyImageData(width: 580, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
        pricings {
          title
          items {
            title
            subtitle
            price
            unit
            element
          }
        }
      }
    }
  }
`;

export default PortfolioBabyPage;
