import styled from 'styled-components';

import { getMargin } from '../../../styles/helper/stylesHelper';
import { Device, Responsive } from '../../../styles/theme/responsive';

export interface BodyProps {
  reverse: boolean;
}

export const Body = styled.div<BodyProps>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? `row-reverse` : `row`)};
  @media ${Responsive.lessThan(Device.mobile)} {
    flex-direction: column;
    display: flex;
    align-items: center;
    margin-bottom: ${getMargin('baseMobile')};
    :last-child {
      margin-bottom: 0;
    }
  }
`;

// Portfolios styled

export const ImageTextSectionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${Responsive.lessThan(Device.tablet)} {
    flex: 1;
    flex-wrap: none;
    flex-direction: column;
  }
`;

// Portfolio styled

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  background-size: contain;
  background-color: white;
  margin: 0 ${getMargin('base')} ${getMargin('base')} 0;

  @media ${Responsive.lessThan(Device.tablet)} {
    width: 100%;
    margin: 0 ${getMargin('baseMobile')} ${getMargin('base')} ${getMargin('baseMobile')};
  }
`;

export const ImageWrapper = styled.div`
  background-size: contain;

  @media ${Responsive.lessThan(Device.tablet)} {
    align-self: center;
  }
`;
export const Image = styled.img`
  width: 300px;
  height: 200px;

  @media ${Responsive.lessThan(Device.tablet)} {
    width: 150px;
    height: 150px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${getMargin('base')};
`;

export const Title = styled.h2`
  text-transform: uppercase;
  display: flex;
  margin-bottom: ${getMargin('base')};
`;

export const SubTitle = styled.p`
  display: flex;
  margin: 0;
`;
