import React from 'react';

import { Body, Content, Image, ImageWrapper, SubTitle, Title, Wrapper } from './styled';

export interface ImageTextSectionProps {
  image: string;
  title: string;
  subtitle: string;
  reverse?: number;
}

export const ImageTextSection: React.FC<ImageTextSectionProps> = ({
  image,
  title,
  subtitle,
  reverse = true,
}) => {
  return (
    <Wrapper>
      <Body reverse={reverse}>
        <ImageWrapper>
          <Image src={image} />
        </ImageWrapper>
        <Content>
          <Title>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
        </Content>
      </Body>
    </Wrapper>
  );
};
